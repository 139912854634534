import { Modal, Form, Row, Spin, Select, Input } from "antd";
import { useState } from "react";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../Notifications/NotificationsUtils";
import { theme } from "../../theme";
import { useTranslation } from "react-i18next";
import CustomModalFooter from "../../Containers/CustomModalFooter";
import styles from "./Students.module.scss";
import { studentExperienceLevels } from "../../utils/constants";
import { useQuery } from "react-query";
import { getStudentPoliJobsContactDetails } from "../../utils/reactQueriesConstants";
import {
	addPoliJobsContactDetails,
	getPoliJobsContactDetailsForStudent,
	updatePoliJobsContactDetails,
} from "../../Requests/polijobs-contact-details-requests";
import { PoliJobsContactDetailsAddUpdateDTO } from "../../Api";

const { Option } = Select;

const PoliJobsContactDetailsModal = (props: {
	type: string;
	studentId: string;
	visible: boolean;
	setVisible: (value: boolean) => void;
	onSuccess: () => void;
	onClose: () => void;
}) => {
	const { t } = useTranslation();
	const [spinning, setSpinning] = useState(false);
	const [contactDetailsId, setContactDetailsId] = useState("");
	const [form] = Form.useForm();

	useQuery(
		[getStudentPoliJobsContactDetails, props.studentId],
		async () => {
			form.setFieldsValue({
				phone: undefined,
				personalEmail: undefined,
				experienceLevel: undefined,
			});

			return await getPoliJobsContactDetailsForStudent(props.studentId);
		},
		{
			refetchOnWindowFocus: false,
			onError: (err) => {
				if (props.type === "edit") {
					openGetErrorNotification(err);
				}
			},
			onSuccess: (data) => {
				if (data) {
					setContactDetailsId(data.id!);
					form.setFieldsValue({
						phone: data.phone,
						personalEmail: data.personalEmail,
						experienceLevel: data.experienceLevel,
					});
				}
			},
		}
	);

	const openGetErrorNotification = (_error: any) => {
		openNotification(
			t("internships.error"),
			t("internships.fetchDataError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const openSuccessNotification = () => {
		openNotification(
			t("students.editContactDetails"),
			t("students.editContactDetailsSuccess"),
			NOTIFICATION_TYPES.SUCCESS,
			6
		);
	};

	const openFailureNotification = () => {
		openNotification(
			t("students.editContactDetails"),
			t("students.editContactDetailsFailure"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const handleClose = () => {
		props.setVisible(false);
	};

	const handleSave = () => {
		const state = form.getFieldsValue();

		if (!state.phone || !state.personalEmail || !state.experienceLevel) {
			openNotification(
				t("account.saveError"),
				t("usersText.requiredFieldsError"),
				NOTIFICATION_TYPES.ERROR
			);
		} else {
			setSpinning(true);
			let obj: PoliJobsContactDetailsAddUpdateDTO = {
				phone: state.phone,
				personalEmail: state.personalEmail,
				experienceLevel: state.experienceLevel,
			};

			(contactDetailsId
				? updatePoliJobsContactDetails(contactDetailsId, obj)
				: addPoliJobsContactDetails(props.studentId, obj)
			)
				.then(openSuccessNotification)
				.then(props.onSuccess)
				.catch(openFailureNotification);
			setSpinning(false);
			form.setFieldsValue({
				phone: undefined,
				personalEmail: undefined,
				experienceLevel: undefined,
			});
			props.setVisible(false);
			props.onClose();
		}
	};

	return (
		<Modal
			open={props.visible}
			onOk={() => form.submit()}
			onCancel={handleClose}
			title={t("students.editContactDetails")}
			width={window.innerWidth > 1215 ? "55%" : "80%"}
			footer={
				<CustomModalFooter
					handleClose={handleClose}
					spinning={spinning}
					handleSave={() => form.submit()}
					confirmButtonName={t("account.save")}
				/>
			}
		>
			<Spin spinning={spinning}>
				<Form.Provider onFormFinish={handleSave}>
					<Form className="w-full" form={form} layout={theme.layout}>
						<div className="w-full">
							<Row className="w-full">
								<Form.Item
									className="w-full"
									name="phone"
									label={t("students.contactDetailsForm.phone") + ":"}
									rules={[
										{
											required: true,
											message: t("usersText.requiredField", {
												field: t("students.contactDetailsForm.phone"),
											}),
											whitespace: true,
										},
									]}
								>
									<Input placeholder={t("students.contactDetailsForm.phone")} />
								</Form.Item>
							</Row>
							<Row className="w-full">
								<Form.Item
									className="w-full"
									name="personalEmail"
									label={t("students.contactDetailsForm.personalEmail") + ":"}
									rules={[
										{
											required: true,
											message: t("usersText.requiredField", {
												field: t("students.contactDetailsForm.personalEmail"),
											}),
											whitespace: true,
										},
									]}
								>
									<Input
										placeholder={t("students.contactDetailsForm.personalEmail")}
									/>
								</Form.Item>
							</Row>
							<Row className="w-full">
								<Form.Item
									className="w-full"
									name="experienceLevel"
									label={t("students.contactDetailsForm.experienceLevel") + ":"}
									rules={[
										{
											required: true,
											message: t("usersText.requiredField", {
												field: t("students.contactDetailsForm.experienceLevel"),
											}),
											whitespace: true,
										},
									]}
								>
									<Select
										className={styles.antSelect}
										allowClear
										filterOption={false}
										placeholder={t(
											"students.contactDetailsForm.experienceLevel"
										)}
									>
										{studentExperienceLevels.map((expLvl) => (
											<Option key={expLvl} value={expLvl}>
												{t("students.experienceLevel." + expLvl)}
											</Option>
										))}
									</Select>
								</Form.Item>
							</Row>
						</div>
					</Form>
				</Form.Provider>
			</Spin>
		</Modal>
	);
};

export default PoliJobsContactDetailsModal;
