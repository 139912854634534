/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
	ExperienceLevel,
	ExperienceLevelFromJSON,
	ExperienceLevelFromJSONTyped,
	ExperienceLevelToJSON,
} from "./ExperienceLevel";

/**
 *
 * @export
 * @interface PoliJobsContactDetailsAddUpdateDTO
 */
export interface PoliJobsContactDetailsAddUpdateDTO {
	/**
	 *
	 * @type {string}
	 * @memberof PoliJobsContactDetailsAddUpdateDTO
	 */
	phone: string;
	/**
	 *
	 * @type {string}
	 * @memberof PoliJobsContactDetailsAddUpdateDTO
	 */
	personalEmail: string;
	/**
	 *
	 * @type {ExperienceLevel}
	 * @memberof PoliJobsContactDetailsAddUpdateDTO
	 */
	experienceLevel: ExperienceLevel;
}

export function PoliJobsContactDetailsAddUpdateDTOFromJSON(
	json: any
): PoliJobsContactDetailsAddUpdateDTO {
	return PoliJobsContactDetailsAddUpdateDTOFromJSONTyped(json, false);
}

export function PoliJobsContactDetailsAddUpdateDTOFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): PoliJobsContactDetailsAddUpdateDTO {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		phone: json["phone"],
		personalEmail: json["personalEmail"],
		experienceLevel: ExperienceLevelFromJSON(json["experienceLevel"]),
	};
}

export function PoliJobsContactDetailsAddUpdateDTOToJSON(
	value?: PoliJobsContactDetailsAddUpdateDTO | null
): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		phone: value.phone,
		personalEmail: value.personalEmail,
		experienceLevel: ExperienceLevelToJSON(value.experienceLevel),
	};
}
