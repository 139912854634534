import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { PoliJobsProposalDTO } from "../../Api";
import moment from "moment/moment";
import { isUndefined } from "lodash";

export const AddPoliJobsProposalFormInit = (
	defaultValues?: PoliJobsProposalDTO
) => {
	const getDefaultValues = (initialData?: any) => {
		let defaultValues: any = {
			name: "",
			company: "",
			url: "",
			location: "",
			numberOfPositions: 1,
			startingDate: moment(),
			availabilityAd: [moment(), moment().add(moment.duration(30, "days"))],
		};

		if (!isUndefined(initialData)) {
			defaultValues = {
				...defaultValues,
				...initialData,
			};
		}

		return defaultValues;
	};

	defaultValues = getDefaultValues(defaultValues);
	const { t } = useTranslation();
	let schema = yup.object().shape({
		name: yup
			.string()
			.required(
				t("polijobs.addForm.requiredField", {
					field: t("polijobs.addForm.offerTitle"),
				})
			)
			.max(250, t("polijobs.addForm.nameLengthError")),
		companyDescription: yup.string(),
		company: yup.string().required(
			t("polijobs.addForm.requiredField", {
				field: t("polijobs.addForm.company"),
			})
		),
		url: yup.string().max(1000, t("polijobs.addForm.urlLengthError")),
		location: yup.string().required(
			t("polijobs.addForm.requiredField", {
				field: t("polijobs.addForm.location"),
			})
		),
		numberOfPositions: yup
			.number()
			.min(0)
			.required(
				t("polijobs.addForm.requiredField", {
					field: t("polijobs.addForm.numberOfPositions"),
				})
			)
			.typeError(t("polijobs.addForm.requiredNumber")),
		startingDate: yup.string().required(
			t("polijobs.addForm.requiredField", {
				field: t("polijobs.addForm.startingDate"),
			})
		),
		availabilityAd: yup.array().required(
			t("polijobs.addForm.requiredField", {
				field: t("polijobs.addForm.availabilityAd"),
			})
		),
	});

	const yupSync = {
		async validator({ field }: any, value: any) {
			await schema.validateSyncAt(field, { [field]: value });
		},
	};

	return { defaultValues, yupSync };
};
