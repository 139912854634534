import {
	ExperienceLevel,
	InternshipEngagementType,
	Lang,
	PracticeCertificateCriterion,
	PracticeCertificateQualifier,
	Role,
	StudentYearEnum,
	TraineesFilterEnum,
} from "../Api";

// const { Option } = Select;

export const userSkills = [
	// <Option key={1} value={"C++"}>C++</Option>,
	// <Option key={2} value={"Java"}>Java</Option>,
	// <Option key={3} value={"Python"}>Python</Option>,
	// <Option key={4} value={"C#"}>C#</Option>
];

export const userYears = [
	StudentYearEnum.L1,
	StudentYearEnum.L2,
	StudentYearEnum.L3,
	StudentYearEnum.L4,
	StudentYearEnum.M1,
	StudentYearEnum.M2,
	StudentYearEnum.D1,
	StudentYearEnum.D2,
	StudentYearEnum.D3,
	StudentYearEnum.D4,
	StudentYearEnum.D5,
];

export const studentExperienceLevels = [
	ExperienceLevel.Junior,
	ExperienceLevel.Middle,
	ExperienceLevel.Senior,
];

export const cvLangs = [Lang.Ro, Lang.En, Lang.Fr, Lang.De];

export const userYearsBachelor = [
	StudentYearEnum.L1,
	StudentYearEnum.L2,
	StudentYearEnum.L3,
	StudentYearEnum.L4,
];

export const userYearsMaster = [StudentYearEnum.M1, StudentYearEnum.M2];

export const userYearsSummerSchool = [
	StudentYearEnum.L1,
	StudentYearEnum.L2,
	StudentYearEnum.L3,
	StudentYearEnum.L4,
	StudentYearEnum.M1,
	StudentYearEnum.M2,
];

export const userYearsInternship = [
	StudentYearEnum.L4,
	StudentYearEnum.M1,
	StudentYearEnum.M2,
];

export const onlyInternshipTypes = [InternshipEngagementType.Internship];

export const companyInternshipTypes = [
	//InternshipEngagementType.BachelorsInternship,
	//InternshipEngagementType.MastersInternship,
	InternshipEngagementType.Internship,
	InternshipEngagementType.Scholarship,
	InternshipEngagementType.SummerSchool,
	//InternshipEngagementType.Job,
	InternshipEngagementType.Event,
];

export const professorInternshipTypes = [
	InternshipEngagementType.BachelorsInternship,
	InternshipEngagementType.MastersInternship,
	InternshipEngagementType.ResearchInternship,
	InternshipEngagementType.Internship,
	InternshipEngagementType.SummerSchool,
	InternshipEngagementType.Event,
];

export const professorInternshipTypesACS = [
	InternshipEngagementType.BachelorsInternship,
	InternshipEngagementType.MastersInternship,
	InternshipEngagementType.ResearchInternship,
	InternshipEngagementType.Internship,
	InternshipEngagementType.SummerSchool,
	InternshipEngagementType.Event,
];

export const qualifiers = [
	PracticeCertificateQualifier.Fb,
	PracticeCertificateQualifier.B,
	PracticeCertificateQualifier.S,
	PracticeCertificateQualifier.Ns,
];

export const criterions = [
	PracticeCertificateCriterion.Disciplinary,
	PracticeCertificateCriterion.Work,
	PracticeCertificateCriterion.Knowledge,
	PracticeCertificateCriterion.Interaction,
];

export const urlPattern = new RegExp(
	/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/i
);

export const phonePattern = new RegExp(
	/^[+]?[0-90-9]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/i
);

export const faxPattern = new RegExp(
	/[+]?[0-9]{1,3}[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,}/i
);

export const cuiPattern = new RegExp(/^(RO)?\d{2,10}$/);

export const groupPattern = new RegExp(/[0-9]{3,4}[A-Z][A-Z0-9]*/);

export const rolesArray = [
	Role.Admin,
	Role.Dean,
	Role.FacultyAdmin,
	Role.DepartmentDirector,
	Role.InternshipAdmin,
	Role.Professor,
	Role.Rector,
	Role.GeneralDirector,
	Role.EconomicDirector,
	Role.LegalCounselor,
	Role.PreventiveFinancialControl,
	Role.UniversityAccordResponsible,
];

export const deanRolesArray = [
	Role.DepartmentDirector,
	Role.FacultyAdmin,
	Role.InternshipAdmin,
];

export const facultyAdminRolesArray = [
	Role.DepartmentDirector,
	Role.FacultyAdmin,
	Role.InternshipAdmin,
	Role.Dean,
];

export const emptyGuid = "00000000-0000-0000-0000-000000000000";

export const traineesFilters = [
	TraineesFilterEnum.EngagementType,
	TraineesFilterEnum.StudyProgram,
	TraineesFilterEnum.Year,
	TraineesFilterEnum.Faculty,
	TraineesFilterEnum.Status,
	TraineesFilterEnum.View,
];

export const approvedStudentsFilters = [
	TraineesFilterEnum.EngagementType,
	TraineesFilterEnum.StudyProgram,
	TraineesFilterEnum.Year,
];

export const dateFormatList = "YYYY-MM-DD";
export const dateFormatListEvent = "YYYY-MM-DD hh:mm";
