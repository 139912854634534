import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	Button,
	List,
	Modal,
	Row,
	Select,
	Space,
	Spin,
	Steps,
	Typography,
	Upload,
} from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import {
	EnrollmentStatus,
	ErrorCodes,
	InternshipDocumentState,
	InternshipEngagementType,
	InternshipEnrollmentDTO,
	InternshipType,
	PracticeCertificateSigningEnum,
	PracticeCertificateState,
	PracticeNotebookSigningEnum,
	PracticeNotebookState,
} from "../../Api";
import ConfirmationModal from "../../Containers/ConfirmationModal";
import CustomModalFooter from "../../Containers/CustomModalFooter";
import { listAllCompanyUsersForCompany } from "../../Requests/company-users-requests";
import {
	cancel,
	getAllApplications,
	updateInternshipEnrollmentTutor,
} from "../../Requests/internship-requests";
import {
	downloadPracticeCertificate,
	generatePracticeCertificate,
	uploadFinalizedPracticeCertificate,
} from "../../Requests/practice-certificate-requests";
import { downloadPracticeConvention } from "../../Requests/practice-convention-requests";
import {
	downloadPracticeNotebook,
	generatePracticeNotebook,
	uploadFinalizedPracticeNotebook,
} from "../../Requests/practice-notebook-requests";
import { theme } from "../../theme";
import { emptyGuid } from "../../utils/constants";
import { downloadFile } from "../../utils/downloadUtils";
import {
	getInternshipApplication,
	listCompanyUsersForCompany,
} from "../../utils/reactQueriesConstants";
import { getErrorFromResponse } from "../../utils/responseUtils";
import { isUserLogged } from "../../utils/utilFunctions";
import styles from "../InternshipInfo/InternshipInfo.module.scss";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../Notifications/NotificationsUtils";
import { studentInternshipEnrollmentIdSet } from "../Student/StudentSlice";
import InternshipActions from "./InternshipActions";

const { Option } = Select;

const InternshipDetailedView = (props: any) => {
	const { t } = useTranslation();
	const [internship, setInternship] = useState<any>();
	const [pageSize, setPageSize] = useState(10);
	const [currentPage, setCurrent] = useState(1);
	const [skills, setSkills] = useState([]);
	const [categories, setCategories] = useState([]);
	const [types, setTypes] = useState([]);
	const [locations, setLocations] = useState([]);
	const [searchTerm, setSearchTerm] = useState("");
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [isTutorModalVisible, setIsTutorModalVisible] = useState(false);
	const [spinning, setSpinning] = useState(false);
	const [selectedTutor, setSelectedTutor] = useState("");
	const [
		cancelApplicationModalVisibility,
		setCancelApplicationModalVisibility,
	] = useState(false);
	const [
		isPracticeCertificateModalVisible,
		setIsPracticeCertificateModalVisible,
	] = useState(false);
	const [isNotebookModalVisible, setIsNotebookModalVisible] = useState(false);
	const dispatch = useDispatch();
	const history = useHistory();

	const openGetApplicationsErrorNotification = (_error: any) => {
		openNotification(
			t("internships.error"),
			t("internships.fetchApplicationsError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const openNotificationFetchError = (ex: any) => {
		if (ex.status) {
			openNotification(
				t("usersText.errorTexts.failedUserGet"),
				t("usersText.errorTexts.serverFailedDescription"),
				NOTIFICATION_TYPES.ERROR
			);
		} else {
			openNotification(
				t("usersText.errorTexts.failedUserGet"),
				t("usersText.errorTexts.networkFailedDescription"),
				NOTIFICATION_TYPES.ERROR
			);
		}
	};

	const queryClient = useQueryClient();
	const { data, isLoading } = useQuery(
		[
			getInternshipApplication,
			props.location.pathname,
			currentPage,
			pageSize,
			skills,
			categories,
			locations,
			searchTerm,
			types,
		],
		() => {
			if (props.location.pathname === "/tema-cercetare")
				return getAllApplications(
					skills,
					categories,
					[InternshipEngagementType.MastersInternship],
					locations,
					searchTerm,
					[EnrollmentStatus.Accepted],
					[EnrollmentStatus.Accepted],
					currentPage,
					pageSize
				);
			else if (props.location.pathname === "/lucrare-licenta")
				return getAllApplications(
					skills,
					categories,
					[InternshipEngagementType.BachelorsInternship],
					locations,
					searchTerm,
					[EnrollmentStatus.Accepted],
					[EnrollmentStatus.Accepted],
					currentPage,
					pageSize
				);
			else
				return getAllApplications(
					skills,
					categories,
					[InternshipEngagementType.Internship],
					locations,
					searchTerm,
					[EnrollmentStatus.Accepted],
					[EnrollmentStatus.Accepted],
					currentPage,
					pageSize
				);
		},
		{
			onError: (err) => {
				openGetApplicationsErrorNotification(err);
			},
			onSuccess: (response) => {
				setInternship(() => {
					return response.data;
				});
			},
		}
	);

	const { data: companyUsers } = useQuery(
		[listCompanyUsersForCompany, internship],
		async () => {
			if (internship) {
				return listAllCompanyUsersForCompany(internship[0]?.companyId);
			}
		},
		{
			onError: openNotificationFetchError,
		}
	);

	const cancelApplication = (internship: any) => {
		setSpinning(true);

		cancel(internship.internshipId)
			.then(async () => {
				history.push("/");

				openNotification(
					t("internships.withdrawApplication"),
					t("internships.applicationWithdrawalSuccessMessage"),
					NOTIFICATION_TYPES.SUCCESS
				);
			})
			.catch((_error) => {
				console.error(_error);

				openNotification(
					t("internships.withdrawApplication"),
					t("internships.applicationWithdrawalErrorMessage"),
					NOTIFICATION_TYPES.ERROR
				);
			})
			.finally(() => {
				setSpinning(false);
				setCancelApplicationModalVisibility(false);
			});
	};

	const goToEditNotebookPage = (enrollmentId: any) => {
		dispatch(studentInternshipEnrollmentIdSet(enrollmentId));
		history.push("/editare-caiet-practica");
	};

	const goToEditInternshipDocumentPage = (enrollmentId: any) => {
		dispatch(studentInternshipEnrollmentIdSet(enrollmentId));
		history.push("/editare-document-propunere");
	};

	const downloadFailPracticeConvention = async (error: any) => {
		const errorMessage = await getErrorFromResponse(error);

		let notificationMessage;

		switch (errorMessage?.code) {
			case ErrorCodes.ConventionNotFound:
				notificationMessage = t(
					"practiceConvention.messages.conventionNotFound"
				);
				break;
			case ErrorCodes.StudentNotFound:
				notificationMessage = t("practiceConvention.messages.studentNotFound");
				break;
			case ErrorCodes.DeanNotFound:
				notificationMessage = t(
					"practiceConvention.messages.student.deanNotFound"
				);
				break;
			case ErrorCodes.SupervisorNotFound:
				notificationMessage = t(
					"practiceConvention.messages.supervisorNotFound"
				);
				break;
			case ErrorCodes.RepresentativeNotFound:
				notificationMessage = t(
					"practiceConvention.messages.representativeNotFound"
				);
				break;
			case ErrorCodes.MissingStudentPersonalData:
				notificationMessage = t(
					"practiceConvention.messages.student.missingPersonalData"
				);
				break;
			default:
				notificationMessage = t("practiceConvention.downloadError");
				break;
		}

		openNotification(
			t("practiceConvention.error"),
			notificationMessage,
			NOTIFICATION_TYPES.ERROR
		);
	};

	const downloadConvention = (enrollmentId: any) => {
		downloadFile(
			downloadPracticeConvention(enrollmentId),
			t("practiceConvention.fileName")
		).catch(downloadFailPracticeConvention);
	};

	const returnAction = t("practiceCertificate.uploadedFile");

	const uploadSucceeded = () => {
		openNotification(
			t("practiceCertificate.uploadedFile"),
			t("practiceCertificate.uploadSuccess"),
			NOTIFICATION_TYPES.SUCCESS
		);
	};

	const uploadFailed = (_err: any) => {
		openNotification(
			t("practiceCertificate.error"),
			t("practiceCertificate.uploadError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const uploadFinalizedNotebook = (id: string, file: Blob) => {
		return uploadFinalizedPracticeNotebook(id, file).then(async () => {
			await queryClient.invalidateQueries("getInternship");
		});
	};

	const uploadFinalizedCertificate = (id: string, file: Blob) => {
		return uploadFinalizedPracticeCertificate(id, file).then(async () => {
			await queryClient.invalidateQueries("getInternship");
		});
	};

	const downloadFailPracticeNotebook = (_err: any) => {
		openNotification(
			t("students.downloadPracticeNotebook"),
			t("practiceNotebook.downloadError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const downloadNotebook = (record: InternshipEnrollmentDTO) => {
		if (
			record.practiceNotebookLastSigner ===
				PracticeNotebookSigningEnum.Student &&
			record.practiceNotebookState !==
				PracticeNotebookState.PendingTutorSignature
		) {
			downloadFile(
				downloadPracticeNotebook(record.practiceNotebookLastSignatureId!),
				t("students.practiceNotebook")
			).catch(downloadFailPracticeNotebook);
		} else {
			downloadFile(
				generatePracticeNotebook(record.practiceNotebookId!),
				t("students.practiceNotebook")
			).catch(downloadFailPracticeNotebook);
		}
	};

	const downloadFailPracticeCertificate = (_err: any) => {
		openNotification(
			t("practiceCertificate.error"),
			t("practiceCertificate.downloadError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const downloadCertificate = (record: InternshipEnrollmentDTO) => {
		if (
			record.practiceCertificateLastSigner ===
				PracticeCertificateSigningEnum.PracticeRepresentative ||
			record.practiceCertificateLastSigner ===
				PracticeCertificateSigningEnum.Student
		) {
			downloadFile(
				downloadPracticeCertificate(record.practiceCertificateLastSignatureId!),
				t("students.practiceCertificate")
			).catch(downloadFailPracticeCertificate);
		} else {
			downloadFile(
				generatePracticeCertificate(record.practiceCertificateId!),
				t("students.practiceCertificate")
			).catch(downloadFailPracticeCertificate);
		}
	};

	const updateTutor = (enrollmentId: string, tutorId: string) => {
		setSpinning(true);

		updateInternshipEnrollmentTutor(enrollmentId, tutorId)
			.then(async () => {
				openNotification(
					t("students.changeTutor"),
					t("students.successChangeTutorMessage"),
					NOTIFICATION_TYPES.SUCCESS
				);
			})
			.catch(async (error) => {
				const errorMessage = await getErrorFromResponse(error);

				let notificationMessage;

				switch (errorMessage?.code) {
					case ErrorCodes.InternshipEnrollmentNotFound:
						notificationMessage = t("internships.enrollmentNotFound");
						break;
					case ErrorCodes.UserNotFound:
						notificationMessage = t("students.tutorNotFound");
						break;
					case ErrorCodes.Forbidden:
						notificationMessage = t("students.noPermissions");
						break;
					case ErrorCodes.InvalidInternshipType:
						notificationMessage = t("students.invalidInternshipType");
						break;
					case ErrorCodes.InvalidTutor:
						notificationMessage = t("students.invalidTutor");
						break;
					default:
						notificationMessage = t("students.errorChangeTutorMessage");
						break;
				}

				openNotification(
					t("students.changeTutor"),
					notificationMessage,
					NOTIFICATION_TYPES.ERROR
				);
			})
			.finally(() => {
				setSpinning(false);
				setIsTutorModalVisible(false);
			});
	};

	const handleCancel = () => {
		setSelectedTutor("");
		setIsTutorModalVisible(false);
	};

	const showPracticeCertificateInfo = () => {
		setIsPracticeCertificateModalVisible(true);
	};

	const handlePracticeCertificateModalCancel = () => {
		setIsPracticeCertificateModalVisible(false);
	};

	const showNotebookInfo = () => {
		setIsNotebookModalVisible(true);
	};

	const handleNotebookModalCancel = () => {
		setIsNotebookModalVisible(false);
	};

	const currentStatusPracticeCertificate = () => {
		if (
			internship[0]?.practiceCertificateState ===
			PracticeCertificateState.NotGenerated
		) {
			return 0;
		} else if (
			internship[0]?.practiceCertificateState ===
			PracticeCertificateState.InProgress
		) {
			return 1;
		} else if (
			internship[0]?.practiceCertificateState ===
			PracticeCertificateState.PendingPracticeRepresentativeSignature
		) {
			return 2;
		} else if (
			internship[0]?.practiceCertificateState ===
			PracticeCertificateState.PendingInternshipAdminValidation
		) {
			return 3;
		} else if (
			internship[0]?.practiceCertificateState ===
			PracticeCertificateState.Finalized
		) {
			return 4;
		}
	};

	const currentStatusNotebook = () => {
		if (
			internship[0]?.practiceNotebookState ===
			PracticeNotebookState.NotGenerated
		) {
			return 0;
		} else if (
			internship[0]?.practiceNotebookState === PracticeNotebookState.InProgress
		) {
			return 1;
		} else if (
			internship[0]?.practiceNotebookState ===
			PracticeNotebookState.PendingTutorSignature
		) {
			return 2;
		} else if (
			internship[0]?.practiceNotebookState ===
			PracticeNotebookState.PendingInternshipAdminValidation
		) {
			return 3;
		} else if (
			internship[0]?.practiceNotebookState === PracticeNotebookState.Finalized
		) {
			return 4;
		}
	};

	return (
		<div className="flex flex-col px-4 py-10">
			<Spin size="large" spinning={isLoading} tip={t("internships.loading")}>
				{internship && (
					<>
						{internship[0] && (
							<div className="flex flex-wrap flex-col items-center pt-2.5">
								<div className="text-[25px] font-bold">
									{internship[0]?.internshipName}
								</div>
								<div className="pt-4">
									<InternshipActions item={internship[0]!} activeTab={""} />
								</div>
							</div>
						)}
						{internship[0]?.skillsRequired &&
						internship[0]?.skillsRequired.length > 0 ? (
							<>
								<div className="text-xl text-left font-bold mt-5 mb-2.5">
									{t("internships.requiredSkills")}
								</div>
								<List
									grid={{ gutter: 10 }}
									dataSource={internship[0]?.skillsRequired}
									renderItem={(item: string) => (
										<List.Item>
											<div className="bg-primary text-white text-center rounded-[12px] py-[5px] px-3 min-w-[65px]">
												{item}
											</div>
										</List.Item>
									)}
								/>
							</>
						) : null}
						{isUserLogged() &&
							internship[0]?.recruiterStatus === EnrollmentStatus.Accepted &&
							internship[0]?.studentStatus === EnrollmentStatus.Accepted &&
							!internship[0]?.hasGeneratedPracticeConvention && (
								<div className="pt-3">
									<Button
										className="bg-primary text-white text-center rounded-[10px] py-[5px] px-3"
										style={{ boxShadow: "rgba(0, 0, 0, 0.18) 0px 2px 4px" }}
										onClick={() => {
											setCancelApplicationModalVisibility(true);
										}}
										title={t("internships.reject")}
										icon={
											<FontAwesomeIcon
												icon={solid("ban")}
												style={{ paddingRight: "5%" }}
											/>
										}
									>
										{t("internships.reject")}
									</Button>
									<ConfirmationModal
										modalText={
											t("internships.withdrawInternshipMessage") +
											t("internships.withdrawInternshipMessage2")
										}
										handleFunction={() => cancelApplication(internship[0])}
										modalVisibility={cancelApplicationModalVisibility}
										title=""
										changeModalVisibility={() =>
											setCancelApplicationModalVisibility(false)
										}
										spinning={spinning}
									/>
								</div>
							)}
						<div className="text-xl text-left font-bold mt-5 mb-2.5">
							{t("internships.description")}
						</div>
						<div
							className={styles.description}
							dangerouslySetInnerHTML={{
								__html: internship[0]?.description || "",
							}}
						/>
						<div className="text-xl text-left font-bold mt-5 mb-2.5">
							{t("internships.documentsStatus")}
						</div>
						<div>
							<Row>
								{t("students.practiceCertificateStatus")}:{" "}
								{t(
									"internships.filters." +
										internship[0]?.practiceCertificateState
								)}
								<FontAwesomeIcon
									icon={solid("circle-info")}
									onClick={showPracticeCertificateInfo}
									style={{
										marginLeft: "10px",
										cursor: "pointer",
										height: "1.2rem",
									}}
								/>
								<Modal
									title={t("students.practiceCertificateStatus")}
									open={isPracticeCertificateModalVisible}
									onCancel={handlePracticeCertificateModalCancel}
									footer={null}
								>
									<Steps
										direction="vertical"
										current={currentStatusPracticeCertificate()}
									>
										<Steps.Step
											title={t(
												"students.internshipDocumentStatuses.NotGenerated"
											)}
											description={
												t("practiceCertificate.description.NotGenerated") +
												internship[0].tutorName
											}
										/>
										<Steps.Step
											title={t(
												"students.internshipDocumentStatuses.InProgress"
											)}
											description={t(
												"practiceCertificate.description.InProgress"
											)}
										/>
										<Steps.Step
											title={t(
												"internships.filters.PendingPracticeRepresentativeSignature"
											)}
											description={t(
												"practiceCertificate.description.PendingPracticeRepresentativeSignature"
											)}
										/>
										<Steps.Step
											title={t(
												"internships.filters.PendingInternshipAdminValidation"
											)}
											description={
												t(
													"practiceCertificate.description.PendingInternshipAdminValidation1"
												) +
												internship[0].supervisorName +
												t(
													"practiceCertificate.description.PendingInternshipAdminValidation2"
												)
											}
										/>
										<Steps.Step
											title={t("internships.filters.Finalized")}
											description={t(
												"practiceCertificate.description.Finalized"
											)}
										/>
									</Steps>
								</Modal>
							</Row>
							<Row>
								{t("students.practiceNotebookStatus")}:{" "}
								{t(
									"internships.filters." + internship[0]?.practiceNotebookState
								)}
								<FontAwesomeIcon
									icon={solid("circle-info")}
									onClick={showNotebookInfo}
									style={{
										marginLeft: "10px",
										cursor: "pointer",
										height: "1.2rem",
									}}
								/>
								<Modal
									title={t("students.practiceNotebookStatus")}
									open={isNotebookModalVisible}
									onCancel={handleNotebookModalCancel}
									footer={null}
								>
									<Steps direction="vertical" current={currentStatusNotebook()}>
										<Steps.Step
											title={t(
												"students.internshipDocumentStatuses.NotGenerated"
											)}
											description={
												t("practiceNotebook.description.NotGenerated") +
												internship[0].tutorName
											}
										/>
										<Steps.Step
											title={t(
												"students.internshipDocumentStatuses.InProgress"
											)}
											description={t("practiceNotebook.description.InProgress")}
										/>
										<Steps.Step
											title={t("internships.filters.PendingTutorSignature")}
											description={
												t(
													"practiceNotebook.description.PendingTutorSignature1"
												) +
												internship[0].tutorName +
												t("practiceNotebook.description.PendingTutorSignature2")
											}
										/>
										<Steps.Step
											title={t(
												"internships.filters.PendingInternshipAdminValidation"
											)}
											description={
												t(
													"practiceNotebook.description.PendingInternshipAdminValidation1"
												) +
												internship[0].supervisorName +
												t(
													"practiceNotebook.description.PendingInternshipAdminValidation2"
												)
											}
										/>
										<Steps.Step
											title={t("internships.filters.Finalized")}
											description={t("practiceNotebook.description.Finalized")}
										/>
									</Steps>
								</Modal>
							</Row>
						</div>
						<div className={styles.buttonsContainer}>
							<Row style={{ marginTop: "2em" }}>
								<Space
									size="middle"
									style={{
										display: "flex",
										flexDirection: "row",
										alignItems: "baseline",
									}}
								>
									{isUserLogged() &&
									internship[0]?.recruiterStatus ===
										EnrollmentStatus.Accepted &&
									internship[0]?.studentStatus === EnrollmentStatus.Accepted &&
									internship[0]?.internshipType === InternshipType.ByCompany ? (
										<>
											<Button
												onClick={() => {
													setIsTutorModalVisible(true);
													setSelectedTutor(internship[0]?.tutorId);
												}}
												title={t("students.changeTutor")}
												className="bg-primary text-white text-center rounded-[10px] py-[5px] px-3"
												style={{ boxShadow: "rgba(0, 0, 0, 0.18) 0px 2px 4px" }}
											>
												<Space>
													{
														<FontAwesomeIcon
															icon={solid("pencil")}
															title={t("students.changeTutor")}
														/>
													}
													{t("students.changeTutor")}
												</Space>
											</Button>
											<Modal
												open={isTutorModalVisible}
												maskClosable={!spinning}
												onCancel={handleCancel}
												onOk={() =>
													updateTutor(
														internship[0]?.internshipEnrollmentId,
														selectedTutor
													)
												}
												title={t("students.changeTutor")}
												width={window.innerWidth > 1215 ? "50%" : "80%"}
												footer={
													<CustomModalFooter
														handleClose={handleCancel}
														spinning={spinning}
														handleSave={() =>
															updateTutor(
																internship[0]?.internshipEnrollmentId,
																selectedTutor
															)
														}
														confirmButtonName={t("account.confirm")}
													/>
												}
											>
												<Spin spinning={spinning}>
													<p>{t("students.selectTutor")}</p>
													<Row>
														<Select
															defaultValue={internship[0]?.tutorId}
															onChange={(event) => setSelectedTutor(event)}
															style={{ width: "18rem" }}
														>
															{companyUsers?.map((item, index) => (
																<Option value={item.id} key={"user" + index}>
																	{item.name}
																</Option>
															))}
														</Select>
													</Row>
												</Spin>
											</Modal>
										</>
									) : null}

									{isUserLogged() &&
									internship[0]?.recruiterStatus ===
										EnrollmentStatus.Accepted &&
									internship[0]?.studentStatus === EnrollmentStatus.Accepted &&
									internship[0]?.engagementType ===
										InternshipEngagementType.Internship &&
									internship[0]?.internshipType === InternshipType.ByCompany ? (
										<>
											<Button
												disabled={internship[0]?.supervisorId === emptyGuid}
												onClick={() => setIsModalVisible(true)}
												title={
													internship[0]?.supervisorId === emptyGuid
														? t(
																"usersText.errorTexts.downloadConventionForbidden"
														  )
														: t("practiceConvention.download")
												}
												className="bg-primary text-white text-center rounded-[10px] py-[5px] px-3"
												style={{ boxShadow: "rgba(0, 0, 0, 0.18) 0px 2px 4px" }}
											>
												<Space>
													{
														<FontAwesomeIcon
															icon={solid("download")}
															title={t("practiceConvention.download")}
														/>
													}
													{t("practiceConvention.download")}
												</Space>
											</Button>
											<Modal
												open={isModalVisible}
												onOk={() => {
													downloadConvention(
														internship[0]?.internshipEnrollmentId
													);
													setIsModalVisible(false);
												}}
												onCancel={() => setIsModalVisible(false)}
												width={"50%"}
												footer={
													<Button
														key="submit"
														type="primary"
														onClick={() => {
															downloadConvention(
																internship[0]?.internshipEnrollmentId
															);
															setIsModalVisible(false);
														}}
														style={{
															background: theme.secondColor,
															border: theme.secondColor,
															borderRadius: "10px",
														}}
													>
														{t("modal.accept")}
													</Button>
												}
											>
												<div>
													<Typography>
														{t("modal.confirmDownloadConvention")}
														<span style={{ color: "red", fontWeight: "bold" }}>
															{t("modal.confirmDownloadConvention2")}
														</span>
													</Typography>
												</div>
											</Modal>
										</>
									) : null}

									{isUserLogged() &&
									internship[0]?.recruiterStatus ===
										EnrollmentStatus.Accepted &&
									internship[0]?.studentStatus === EnrollmentStatus.Accepted &&
									internship[0]?.engagementType ===
										InternshipEngagementType.Internship &&
									(internship[0]?.practiceNotebookState ===
										PracticeNotebookState.NotGenerated ||
										internship[0]?.practiceNotebookState ===
											PracticeNotebookState.InProgress ||
										internship[0]?.practiceNotebookState ===
											PracticeNotebookState.PendingTutorSignature) ? (
										<Button
											onClick={() =>
												goToEditNotebookPage(
													internship[0]?.internshipEnrollmentId
												)
											}
											title={t("practiceNotebook.fillPracticeNotebook")}
											className="bg-primary text-white text-center rounded-[10px] py-[5px] px-3"
											style={{ boxShadow: "rgba(0, 0, 0, 0.18) 0px 2px 4px" }}
										>
											<Space>
												{
													<FontAwesomeIcon
														icon={solid("pencil")}
														title={t("practiceNotebook.fillPracticeNotebook")}
													/>
												}
												{t("practiceNotebook.fillPracticeNotebook")}
											</Space>
										</Button>
									) : null}

									{isUserLogged() &&
									internship[0]?.recruiterStatus ===
										EnrollmentStatus.Accepted &&
									internship[0]?.studentStatus === EnrollmentStatus.Accepted &&
									internship[0]?.engagementType ===
										InternshipEngagementType.Internship &&
									internship[0]?.practiceNotebookState ===
										PracticeNotebookState.NotGenerated ? (
										<Upload
											accept=".pdf"
											action={(uploaded) => {
												uploadFinalizedNotebook(
													internship[0]?.internshipEnrollmentId,
													uploaded
												)
													.then(uploadSucceeded)
													.catch(uploadFailed);
												return returnAction;
											}}
											showUploadList={false}
											multiple={false}
											customRequest={({ onSuccess }) => {
												onSuccess && onSuccess("");
											}}
										>
											<Button
												title={t("students.uploadSignedPracticeNotebook")}
												className="bg-primary text-white text-center rounded-[10px] py-[5px] px-3"
												style={{ boxShadow: "rgba(0, 0, 0, 0.18) 0px 2px 4px" }}
											>
												<Space>
													{
														<FontAwesomeIcon
															icon={solid("upload")}
															title={t("students.uploadSignedPracticeNotebook")}
														/>
													}
													{t("students.uploadSignedPracticeNotebook")}
												</Space>
											</Button>
										</Upload>
									) : null}

									{isUserLogged() &&
									internship[0]?.recruiterStatus ===
										EnrollmentStatus.Accepted &&
									internship[0]?.studentStatus === EnrollmentStatus.Accepted &&
									internship[0]?.engagementType ===
										InternshipEngagementType.Internship &&
									internship[0]?.practiceNotebookState ===
										PracticeNotebookState.Finalized ? (
										<Button
											onClick={() => downloadNotebook(internship[0])}
											title={t("students.downloadPracticeNotebook")}
											className="bg-primary text-white text-center rounded-[10px] py-[5px] px-3"
											style={{ boxShadow: "rgba(0, 0, 0, 0.18) 0px 2px 4px" }}
										>
											<Space>
												{
													<FontAwesomeIcon
														icon={solid("download")}
														title={t("students.downloadPracticeNotebook")}
													/>
												}
												{t("students.downloadPracticeNotebook")}
											</Space>
										</Button>
									) : null}

									{isUserLogged() &&
									internship[0]?.recruiterStatus ===
										EnrollmentStatus.Accepted &&
									internship[0]?.studentStatus === EnrollmentStatus.Accepted &&
									internship[0]?.engagementType ===
										InternshipEngagementType.Internship &&
									internship[0]?.practiceCertificateState ===
										PracticeCertificateState.NotGenerated ? (
										<Upload
											accept=".pdf"
											action={(uploaded) => {
												uploadFinalizedCertificate(
													internship[0]?.internshipEnrollmentId,
													uploaded
												)
													.then(uploadSucceeded)
													.catch(uploadFailed);
												return returnAction;
											}}
											showUploadList={false}
											multiple={false}
											customRequest={({ onSuccess }) => {
												onSuccess && onSuccess("");
											}}
										>
											<Button
												title={t("students.uploadSignedPracticeCertificate")}
												className="bg-primary text-white text-center rounded-[10px] py-[5px] px-3"
												style={{ boxShadow: "rgba(0, 0, 0, 0.18) 0px 2px 4px" }}
											>
												<Space>
													{
														<FontAwesomeIcon
															icon={solid("upload")}
															title={t(
																"students.uploadSignedPracticeCertificate"
															)}
														/>
													}
													{t("students.uploadSignedPracticeCertificate")}
												</Space>
											</Button>
										</Upload>
									) : null}

									{isUserLogged() &&
									internship[0]?.recruiterStatus ===
										EnrollmentStatus.Accepted &&
									internship[0]?.studentStatus === EnrollmentStatus.Accepted &&
									internship[0]?.engagementType ===
										InternshipEngagementType.Internship &&
									internship[0]?.practiceCertificateState ===
										PracticeCertificateState.Finalized ? (
										<Button
											onClick={() => downloadCertificate(internship[0])}
											title={t("students.downloadPracticeCertificate")}
											className="bg-primary text-white text-center rounded-[10px] py-[5px] px-3"
											style={{ boxShadow: "rgba(0, 0, 0, 0.18) 0px 2px 4px" }}
										>
											<Space>
												{
													<FontAwesomeIcon
														icon={solid("download")}
														title={t("students.downloadPracticeCertificate")}
													/>
												}
												{t("students.downloadPracticeCertificate")}
											</Space>
										</Button>
									) : null}

									{isUserLogged() &&
									internship[0]?.recruiterStatus ===
										EnrollmentStatus.Accepted &&
									internship[0]?.studentStatus === EnrollmentStatus.Accepted &&
									(internship[0]?.engagementType ===
										InternshipEngagementType.BachelorsInternship ||
										internship[0]?.engagementType ===
											InternshipEngagementType.MastersInternship) &&
									(internship[0]?.internshipDocumentState ===
										InternshipDocumentState.NotGenerated ||
										internship[0]?.internshipDocumentState ===
											InternshipDocumentState.InProgress) ? (
										<Button
											onClick={() =>
												goToEditInternshipDocumentPage(
													internship[0]?.internshipEnrollmentId
												)
											}
											title={t("internshipDocument.fillInternshipDocument")}
											className="bg-primary text-white text-center rounded-[10px] py-[5px] px-3"
											style={{ boxShadow: "rgba(0, 0, 0, 0.18) 0px 2px 4px" }}
										>
											<Space>
												{
													<FontAwesomeIcon
														icon={solid("pencil")}
														title={t(
															"internshipDocument.fillInternshipDocument"
														)}
													/>
												}
												{t("internshipDocument.fillInternshipDocument")}
											</Space>
										</Button>
									) : null}
								</Space>
							</Row>
						</div>
					</>
				)}
			</Spin>
		</div>
	);
};

export default InternshipDetailedView;
