import {
	Breakpoint,
	Button,
	Dropdown,
	Menu,
	MenuProps,
	Pagination,
	Table,
	Typography,
} from "antd";
import { useTranslation } from "react-i18next";
import {
	PoliJobsPotentialCandidateDTO,
	PoliJobsRecommendationStatus,
} from "../../Api";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { studentIdSet } from "../Student/StudentSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { downloadFile } from "../../utils/downloadUtils";
import { downloadStudentCv } from "../../Requests/academic-user-requests";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../Notifications/NotificationsUtils";
import { useQuery, useQueryClient } from "react-query";
import { getPoliJobsProposalRecommendations } from "../../utils/reactQueriesConstants";
import { useCallback, useState } from "react";
import useQueryFilters from "../../Hooks/useQueryFilters";
import {
	acceptRecommendation,
	getRecommendationsForPoliJobsProposal,
	invalidateRecommendation,
} from "../../Requests/polijobs-potential-candidate-requests";
import { ItemType } from "antd/es/menu/hooks/useItems";
import ConfirmationModal from "../../Containers/ConfirmationModal";

enum ActionKeyEnum {
	AcceptRecommendation,
	InvalidateRecommendation,
}

const PoliJobsRecommendationsTable = (props: {
	poliJobsProposalId: string;
}) => {
	const { t, i18n } = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();
	const query = useQueryFilters({});
	const queryClient = useQueryClient();
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [modalTitle, setModalTitle] = useState("");
	const [modalText, setModalText] = useState("");
	const [handleFunction, setHandleFunction] = useState(() => () => {});
	const [spinning, setSpinning] = useState(false);

	let locale = {
		emptyText: t("tableText.noPotentialCandidates"),
	};

	const goToStudentProfile = (id: any) => {
		dispatch(studentIdSet(id));
		history.push("/student");
	};

	const downloadFail = (_err: any) => {
		openNotification(
			t("universityAccord.error"),
			t("universityAccord.downloadError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const openGetErrorNotification = (_error: any) => {
		openNotification(
			t("polijobs.error"),
			t("polijobs.fetchDataError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const { data: recommendations, isLoading: loading } = useQuery(
		[getPoliJobsProposalRecommendations, props.poliJobsProposalId],
		() =>
			getRecommendationsForPoliJobsProposal(
				props.poliJobsProposalId,
				query.filters
			),
		{
			onError: (err) => {
				openGetErrorNotification(err);
			},
		}
	);

	const acceptCandidateRecommendation = (id: string) => {
		setSpinning(true);

		acceptRecommendation(id)
			.then(async () => {
				await queryClient.invalidateQueries(getPoliJobsProposalRecommendations);

				openNotification(
					t("polijobs.acceptRecommendation"),
					t("polijobs.acceptRecommendationSuccess"),
					NOTIFICATION_TYPES.SUCCESS
				);
			})
			.catch((_error) => {
				openNotification(
					t("polijobs.acceptRecommendation"),
					t("polijobs.acceptRecommendationFailure"),
					NOTIFICATION_TYPES.ERROR
				);
			})
			.finally(() => {
				setSpinning(false);
				setIsModalVisible(false);
			});
	};

	const invalidateCandidateRecommendation = (id: string) => {
		setSpinning(true);

		invalidateRecommendation(id)
			.then(async () => {
				await queryClient.invalidateQueries(getPoliJobsProposalRecommendations);

				openNotification(
					t("polijobs.invalidateRecommendation"),
					t("polijobs.invalidateRecommendationSuccess"),
					NOTIFICATION_TYPES.SUCCESS
				);
			})
			.catch((_error) => {
				openNotification(
					t("polijobs.invalidateRecommendation"),
					t("polijobs.invalidateRecommendationFailure"),
					NOTIFICATION_TYPES.ERROR
				);
			})
			.finally(() => {
				setSpinning(false);
				setIsModalVisible(false);
			});
	};

	const getActions = (
		record: PoliJobsPotentialCandidateDTO
	): ItemType[] | undefined => {
		let actions: MenuProps["items"] = [];

		actions.push({
			label: t("polijobs.acceptRecommendation"),
			key: ActionKeyEnum.AcceptRecommendation,
			icon: (
				<FontAwesomeIcon icon={solid("check")} style={{ fontSize: "15px" }} />
			),
			disabled: record.status === PoliJobsRecommendationStatus.Accepted,
			title: t("polijobs.acceptRecommendation"),
		});
		actions.push({
			label: t("polijobs.invalidateRecommendation"),
			key: ActionKeyEnum.InvalidateRecommendation,
			icon: (
				<FontAwesomeIcon icon={solid("cancel")} style={{ fontSize: "15px" }} />
			),
			disabled: record.status === PoliJobsRecommendationStatus.Invalid,
			title: t("polijobs.invalidateRecommendation"),
		});

		return actions;
	};

	const onMenuClick = (e: any, record: PoliJobsPotentialCandidateDTO) => {
		if (e.key == ActionKeyEnum.AcceptRecommendation) {
			setModalTitle(t("polijobs.acceptRecommendation"));
			setModalText(t("polijobs.acceptRecommendationMessage"));
			setIsModalVisible(true);
			setHandleFunction(() => () => acceptCandidateRecommendation(record.id!));
		} else if (e.key == ActionKeyEnum.InvalidateRecommendation) {
			setModalTitle(t("polijobs.invalidateRecommendation"));
			setModalText("polijobs.invalidateRecommendationMessage");
			setIsModalVisible(true);
			setHandleFunction(
				() => () => invalidateCandidateRecommendation(record.id!)
			);
		}
	};

	const columns = [
		{
			title: t("polijobs.name"),
			dataIndex: "candidateName",
			key: "candidateName",
			fixed: "left" as "left",
			render: (
				text: string | undefined,
				record: PoliJobsPotentialCandidateDTO
			) => (
				<a onClick={() => goToStudentProfile(record.candidateId)}>
					<b>{text}</b>
				</a>
			),
		},
		{
			title: t("polijobs.faculty"),
			dataIndex:
				i18n.language === "ro"
					? "candidateFacultyNameRo"
					: "candidateFacultyNameEn",
			key:
				i18n.language === "ro"
					? "candidateFacultyNameRo"
					: "candidateFacultyNameEn",
		},
		{
			title: t("polijobs.studyProgram"),
			dataIndex: "candidateStudyProgram",
			key: "candidateStudyProgram",
		},
		{
			title: t("polijobs.year"),
			dataIndex: "candidateYear",
			key: "candidateYear",
			render: (
				_text: string | undefined,
				record: PoliJobsPotentialCandidateDTO
			) => t("account." + record.candidateYear),
		},
		{
			title: t("polijobs.email"),
			dataIndex: "candidateEmail",
			key: "candidateEmail",
		},
		{
			title: t("polijobs.phone"),
			dataIndex: "candidatePhone",
			key: "candidatePhone",
		},
		{
			title: t("polijobs.cv"),
			dataIndex: "candidateCvFileName",
			key: "candidateCvFileName",
			responsive: ["md"] as unknown as Breakpoint[],
			render: (
				_text: string | undefined,
				record: PoliJobsPotentialCandidateDTO
			) => (
				<Button
					title={"CV"}
					style={{
						backgroundColor: "transparent",
					}}
					onClick={() =>
						downloadFile(
							downloadStudentCv(record.candidateId!),
							t("documents.Cv") + "_" + record.candidateName
						).catch(downloadFail)
					}
				>
					<FontAwesomeIcon
						icon={solid("download")}
						style={{ fontSize: "15px" }}
					/>
				</Button>
			),
		},
		{
			title: t("polijobs.score"),
			dataIndex: "matchingPercentage",
			key: "matchingPercentage",
			render: (
				_text: string | undefined,
				record: PoliJobsPotentialCandidateDTO
			) => `${record.matchingPercentage} %`,
		},
		{
			title: t("polijobs.status"),
			dataIndex: "status",
			key: "status",
			render: (
				_text: string | undefined,
				record: PoliJobsPotentialCandidateDTO
			) => t("polijobs.recommendationStatuses." + record.status),
		},
		{
			title: t("polijobs.actions"),
			dataIndex: "actions",
			fixed: "right" as "right",
			width: 85,
			render: (_text: string | undefined, record: any) => (
				<div>
					<Dropdown
						placement="bottomLeft"
						overlay={
							<Menu
								onClick={(e) => onMenuClick(e, record)}
								items={getActions(record)}
							/>
						}
					>
						<FontAwesomeIcon
							icon={solid("bars")}
							style={{
								cursor: "pointer",
								height: "1rem",
								padding: "6px 0px",
								marginLeft: "13px",
							}}
						/>
					</Dropdown>
				</div>
			),
		},
	];

	const onPageUpdate = useCallback(
		(page: number, pageSize: number) => {
			query.update({
				page: page.toString(),
				pageSize: pageSize.toString(),
			});
		},
		[query]
	);

	return (
		<div className="flex flex-col gap-4">
			<div className="px-4 pb-10 flex flex-col">
				<div className="flex flex-col gap-10">
					<div className="flex flex-col md:flex-row justify-between items-center">
						<Typography.Title level={3} className="my-0">
							{recommendations?.totalCount ?? 0} {t("filtering.results")}
						</Typography.Title>
						{!loading && (
							<Pagination
								defaultCurrent={recommendations?.page}
								defaultPageSize={recommendations?.pageSize}
								total={recommendations?.totalCount}
								onChange={onPageUpdate}
								locale={{ items_per_page: t("pagination") }}
							/>
						)}
					</div>

					<Table
						locale={locale}
						columns={columns}
						dataSource={recommendations?.data ?? []}
						pagination={false}
						scroll={{ x: "calc(300px + 50%)", y: 420 }}
						rowKey={(record) => record.candidateId!}
					/>
				</div>
			</div>
			<ConfirmationModal
				modalText={modalText}
				handleFunction={handleFunction}
				modalVisibility={isModalVisible}
				title={modalTitle}
				changeModalVisibility={() => setIsModalVisible(false)}
				spinning={spinning}
			/>
		</div>
	);
};

export default PoliJobsRecommendationsTable;
