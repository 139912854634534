import { Modal, Form, Row, Spin, Button, Select } from "antd";
import { useEffect, useState } from "react";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../Notifications/NotificationsUtils";
import { theme } from "../../theme";
import { useTranslation } from "react-i18next";
import CustomModalFooter from "../../Containers/CustomModalFooter";
import styles from "./PoliJobsOffers.module.scss";
import { useQuery } from "react-query";
import {
	getFaculties,
	getFacultiesStudyPrograms,
} from "../../utils/reactQueriesConstants";
import { getAllFaculties } from "../../Requests/faculty-requests";
import { getStudyProgramsForFaculties } from "../../Requests/student-study-request";
import { cvLangs, userYears } from "../../utils/constants";
import {
	FacultyDTO,
	PoliJobsProposalCandidateFiltersDTO,
	StudentYearEnum,
	StudyProgramDTO,
} from "../../Api";
import { generateRecommendationsForPoliJobsProposal } from "../../Requests/polijobs-potential-candidate-requests";

const { Option } = Select;

const PoliJobsRecommendationsGenerationModal = (props: {
	poliJobsProposalId: string | undefined;
	invalidationHandler: () => Promise<void>;
}) => {
	const { t, i18n } = useTranslation();
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [spinning, setSpinning] = useState(false);
	const [form] = Form.useForm();
	const facultiesValue = Form.useWatch("faculties", form);
	const [facultyOptions, setFacultyOptions] = useState<any>([]);
	const [facultySearch, setFacultySearch] = useState("");
	const [studyProgramOptions, setStudyProgramOptions] = useState<any>([]);
	const [studyProgramSearch, setStudyProgramSearch] = useState("");

	const { data: allFacultiesList } = useQuery(
		[getFaculties],
		() => getAllFaculties("", 1, 20),
		{
			onError: (err) => {
				openGetErrorNotification(err);
			},
			refetchOnWindowFocus: false,
		}
	);

	const { data: allStudyProgramsList } = useQuery(
		[getFacultiesStudyPrograms, facultiesValue],
		() => getStudyProgramsForFaculties(facultiesValue),
		{
			onError: (err: any) => {
				openGetErrorNotification(err);
			},
			refetchOnWindowFocus: false,
		}
	);

	useEffect(() => {
		setFacultyOptions(
			allFacultiesList
				?.filter(
					(e: FacultyDTO) =>
						facultySearch === "" ||
						(i18n.language === "ro" ? e.facultyNameRo : e.facultyNameEn)
							?.toLowerCase()
							.includes(facultySearch.toLowerCase())
				)
				.filter((faculty: FacultyDTO) => faculty.facultyNameRo !== "Altele")
				.map((faculty: FacultyDTO) => (
					<Option key={faculty.id} value={faculty.id}>
						{i18n.language === "en"
							? faculty.facultyNameEn?.replace("Faculty of", "")
							: faculty.facultyNameRo?.replace("Facultatea de", "")}
					</Option>
				))
		);
	}, [facultySearch, allFacultiesList, i18n.language]);

	useEffect(() => {
		setStudyProgramOptions(
			allStudyProgramsList
				?.filter(
					(e: StudyProgramDTO) =>
						studyProgramSearch === "" ||
						e.name?.toLowerCase().includes(studyProgramSearch.toLowerCase())
				)
				.map((program: StudyProgramDTO) => (
					<Option key={program.id} value={program.id}>
						{program.name}
					</Option>
				))
		);
	}, [studyProgramSearch, allStudyProgramsList]);

	const openGetErrorNotification = (_error: any) => {
		openNotification(
			t("internships.error"),
			t("internships.fetchDataError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const openSuccessNotification = () => {
		openNotification(
			t("polijobs.recommendationsGeneration"),
			t("polijobs.generateRecommendationsSuccess"),
			NOTIFICATION_TYPES.SUCCESS,
			6
		);
	};

	const openFailureNotification = () => {
		openNotification(
			t("polijobs.recommendationsGeneration"),
			t("polijobs.generateRecommendationsFailure"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const handleShow = () => {
		setIsModalVisible(true);
	};

	const handleClose = () => {
		form.setFieldsValue({
			faculties: undefined,
			studyPrograms: undefined,
			years: undefined,
		});
		setIsModalVisible(false);
	};

	const handleSave = () => {
		form.validateFields().then((values) => {
			setSpinning(true);

			let obj: PoliJobsProposalCandidateFiltersDTO = {
				facultyIds: values.faculties,
				studyProgramIds: values.studyPrograms,
				years:
					values.years?.map(
						(e: string) => StudentYearEnum[e as keyof typeof StudentYearEnum]
					) ?? undefined,
				blacklist: values.blacklist,
				whitelist: values.whitelist,
				languages: values.languages,
			};

			generateRecommendationsForPoliJobsProposal(props.poliJobsProposalId!, obj)
				.then(async () => {
					await props.invalidationHandler();
				})
				.then(handleClose)
				.then(openSuccessNotification)
				.catch(openFailureNotification)
				.finally(() => {
					setSpinning(false);
					setIsModalVisible(false);
				});
		});
	};

	const handleSelectedFaculty = (_selected: string) => {
		form.setFieldsValue({
			...form.getFieldsValue(),
			studyPrograms: undefined,
			years: undefined,
		});
	};

	const handleSelectedStudyProgram = (_selected: string) => {
		form.setFieldsValue({
			...form.getFieldsValue(),
			years: undefined,
		});
	};

	return (
		<>
			<Button
				type="primary"
				title={t("polijobs.generateRecommendations")}
				onClick={handleShow}
			>
				{t("polijobs.generateRecommendations")}
			</Button>

			<Modal
				open={isModalVisible}
				onOk={() => form.submit()}
				onCancel={handleClose}
				title={t("polijobs.recommendationsGeneration")}
				width={window.innerWidth > 1215 ? "55%" : "80%"}
				footer={
					<CustomModalFooter
						handleClose={handleClose}
						spinning={spinning}
						handleSave={() => form.submit()}
						confirmButtonName={t("account.save")}
					/>
				}
			>
				<Spin spinning={spinning}>
					<Form.Provider onFormFinish={handleSave}>
						<Form className="w-full" form={form} layout={theme.layout}>
							<div className="w-full">
								<Row className="w-full">
									<Form.Item
										className="w-full"
										name="faculties"
										label={
											t("polijobs.generateRecommendationsForm.faculties") + ":"
										}
										tooltip={t(
											"polijobs.generateRecommendationsForm.facultiesHelper"
										)}
									>
										<Select
											className={styles.antSelect}
											allowClear
											showSearch
											mode="multiple"
											filterOption={false}
											onSearch={setFacultySearch}
											placeholder={t(
												"polijobs.generateRecommendationsForm.faculties"
											)}
											onChange={handleSelectedFaculty}
											notFoundContent={t(
												"polijobs.generateRecommendationsForm.notFoundContent"
											)}
										>
											{facultyOptions}
										</Select>
									</Form.Item>
								</Row>
								<Row className="w-full">
									<Form.Item
										className="w-full"
										name="studyPrograms"
										label={
											t("polijobs.generateRecommendationsForm.studyPrograms") +
											":"
										}
										tooltip={t(
											"polijobs.generateRecommendationsForm.studyProgramsHelper"
										)}
									>
										<Select
											className={styles.antSelect}
											allowClear
											showSearch
											mode="multiple"
											filterOption={false}
											onSearch={setStudyProgramSearch}
											placeholder={t(
												"polijobs.generateRecommendationsForm.studyPrograms"
											)}
											onChange={handleSelectedStudyProgram}
											notFoundContent={t(
												"polijobs.generateRecommendationsForm.notFoundContent"
											)}
										>
											{studyProgramOptions}
										</Select>
									</Form.Item>
								</Row>
								<Row className="w-full">
									<Form.Item
										className="w-full"
										name="years"
										label={
											t("polijobs.generateRecommendationsForm.years") + ":"
										}
										tooltip={t(
											"polijobs.generateRecommendationsForm.yearsHelper"
										)}
									>
										<Select
											className={styles.antSelect}
											allowClear
											mode="multiple"
											filterOption={false}
											placeholder={t(
												"polijobs.generateRecommendationsForm.years"
											)}
											notFoundContent={t(
												"internships.generateRecommendationsForm.notFoundContent"
											)}
										>
											{userYears.map((year) => (
												<Option key={year} value={year}>
													{t("account." + year)}
												</Option>
											))}
										</Select>
									</Form.Item>
								</Row>
								<Row className="w-full">
									<Form.Item
										className="w-full"
										name="blacklist"
										label={
											t("polijobs.generateRecommendationsForm.blacklist") + ":"
										}
										tooltip={t(
											"polijobs.generateRecommendationsForm.blacklistHelper"
										)}
									>
										<Select
											className={styles.antSelect}
											allowClear
											mode="tags"
											filterOption={false}
											placeholder={t(
												"polijobs.generateRecommendationsForm.blacklist"
											)}
										/>
									</Form.Item>
								</Row>
								<Row className="w-full">
									<Form.Item
										className="w-full"
										name="whitelist"
										label={
											t("polijobs.generateRecommendationsForm.whitelist") + ":"
										}
										tooltip={t(
											"polijobs.generateRecommendationsForm.whitelistHelper"
										)}
									>
										<Select
											className={styles.antSelect}
											allowClear
											mode="tags"
											filterOption={false}
											placeholder={t(
												"polijobs.generateRecommendationsForm.whitelist"
											)}
										/>
									</Form.Item>
								</Row>
								<Row className="w-full">
									<Form.Item
										className="w-full"
										name="languages"
										label={
											t("polijobs.generateRecommendationsForm.languages") + ":"
										}
										tooltip={t(
											"polijobs.generateRecommendationsForm.languagesHelper"
										)}
									>
										<Select
											className={styles.antSelect}
											allowClear
											mode="multiple"
											filterOption={false}
											placeholder={t(
												"polijobs.generateRecommendationsForm.languages"
											)}
										>
											{cvLangs.map((lang) => (
												<Option key={lang} value={lang}>
													{t("polijobs.cvLangs." + lang)}
												</Option>
											))}
										</Select>
									</Form.Item>
								</Row>
							</div>
						</Form>
					</Form.Provider>
				</Spin>
			</Modal>
		</>
	);
};

export default PoliJobsRecommendationsGenerationModal;
