import {
	Breakpoint,
	Button,
	Dropdown,
	Form,
	Input,
	Menu,
	MenuProps,
	Modal,
	Pagination,
	Spin,
	Table,
	Typography,
} from "antd";
import { useTranslation } from "react-i18next";
import {
	InternType,
	PoliJobsCandidateDTO,
	PoliJobsProposalEnrollmentStatus,
} from "../../Api";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { studentIdSet } from "../Student/StudentSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { downloadFile } from "../../utils/downloadUtils";
import { downloadStudentCv } from "../../Requests/academic-user-requests";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../Notifications/NotificationsUtils";
import { useQuery, useQueryClient } from "react-query";
import { getPoliJobsProposalStudents } from "../../utils/reactQueriesConstants";
import { useCallback, useState } from "react";
import useQueryFilters from "../../Hooks/useQueryFilters";
import { ItemType } from "antd/es/menu/hooks/useItems";
import ConfirmationModal from "../../Containers/ConfirmationModal";
import {
	acceptApplication,
	editNotes,
	getApplicants,
	rejectApplication,
	rejectCV,
} from "../../Requests/polijobs-proposal-enrollment-requests";
import CustomModalFooter from "../../Containers/CustomModalFooter";
import CustomEditor from "../../CustomComponents/CustomEditor";
import CustomForm from "../../CustomComponents/CustomForm";
import TextArea from "antd/es/input/TextArea";

enum ActionKeyEnum {
	AcceptApplication,
	RejectApplication,
	RejectCV,
	AddNotes,
}

const PoliJobsApplicantsTable = (props: {
	poliJobsProposalId: string;
	type: InternType;
}) => {
	const { t, i18n } = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();
	const query = useQueryFilters({});
	const queryClient = useQueryClient();
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [isCVRejectModalVisible, setIsCVRejectModalVisible] = useState(false);
	const [isEditNotesModalVisible, setIsEditNotesModalVisible] = useState(false);
	const [modalTitle, setModalTitle] = useState("");
	const [modalText, setModalText] = useState("");
	const [handleFunction, setHandleFunction] = useState(() => () => {});
	const [spinning, setSpinning] = useState(false);
	const [rejectForm] = Form.useForm<{
		rejectMessage: string;
	}>();
	const [notesForm] = Form.useForm<{
		notes: string;
	}>();

	let locale = {
		emptyText: t("tableText.noPotentialCandidates"),
	};

	const goToStudentProfile = (id: any) => {
		dispatch(studentIdSet(id));
		history.push("/student");
	};

	const downloadFail = (_err: any) => {
		openNotification(
			t("universityAccord.error"),
			t("universityAccord.downloadError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const openGetErrorNotification = (_error: any) => {
		openNotification(
			t("polijobs.error"),
			t("polijobs.fetchDataError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const { data, isLoading: loading } = useQuery(
		[
			getPoliJobsProposalStudents,
			props.poliJobsProposalId,
			props.type,
			query.filters,
		],
		() => getApplicants(props.poliJobsProposalId, props.type, query.filters),
		{
			onError: (err) => {
				openGetErrorNotification(err);
			},
		}
	);

	const acceptPoliJobsApplication = (id: string) => {
		setSpinning(true);

		acceptApplication(id)
			.then(async () => {
				await queryClient.invalidateQueries(getPoliJobsProposalStudents);

				openNotification(
					t("polijobs.acceptApplication"),
					t("polijobs.acceptApplicationSuccess"),
					NOTIFICATION_TYPES.SUCCESS
				);
			})
			.catch((_error) => {
				openNotification(
					t("polijobs.acceptApplication"),
					t("polijobs.acceptApplicationFailure"),
					NOTIFICATION_TYPES.ERROR
				);
			})
			.finally(() => {
				setSpinning(false);
				setIsModalVisible(false);
			});
	};

	const rejectPoliJobsApplication = (id: string) => {
		setSpinning(true);

		rejectApplication(id)
			.then(async () => {
				await queryClient.invalidateQueries(getPoliJobsProposalStudents);

				openNotification(
					t("polijobs.rejectApplication"),
					t("polijobs.rejectApplicationSuccess"),
					NOTIFICATION_TYPES.SUCCESS
				);
			})
			.catch((_error) => {
				openNotification(
					t("polijobs.rejectApplication"),
					t("polijobs.rejectApplicationFailure"),
					NOTIFICATION_TYPES.ERROR
				);
			})
			.finally(() => {
				setSpinning(false);
				setIsModalVisible(false);
			});
	};

	const rejectStudentCV = (id: string) => {
		setSpinning(true);

		rejectCV(id, rejectForm.getFieldsValue().rejectMessage)
			.then(async () => {
				await queryClient.invalidateQueries(getPoliJobsProposalStudents);

				openNotification(
					t("polijobs.rejectCV"),
					t("polijobs.rejectCVSuccess"),
					NOTIFICATION_TYPES.SUCCESS
				);
			})
			.catch((_error) => {
				openNotification(
					t("polijobs.rejectCV"),
					t("polijobs.rejectCVFailure"),
					NOTIFICATION_TYPES.ERROR
				);
			})
			.finally(() => {
				setSpinning(false);
				setIsCVRejectModalVisible(false);
			});
	};

	const editApplicantNotes = (id: string) => {
		setSpinning(true);

		editNotes(id, notesForm.getFieldsValue().notes)
			.then(async () => {
				await queryClient.invalidateQueries(getPoliJobsProposalStudents);

				openNotification(
					t("polijobs.editNotes"),
					t("polijobs.editNotesSuccess"),
					NOTIFICATION_TYPES.SUCCESS
				);
			})
			.catch((_error) => {
				openNotification(
					t("polijobs.editNotes"),
					t("polijobs.editNotesFailure"),
					NOTIFICATION_TYPES.ERROR
				);
			})
			.finally(() => {
				setSpinning(false);
				setIsEditNotesModalVisible(false);
			});
	};

	const getActions = (record: PoliJobsCandidateDTO): ItemType[] | undefined => {
		if (props.type == InternType.Trainee) return [];

		let actions: MenuProps["items"] = [];

		actions.push({
			label: t("polijobs.acceptApplication"),
			key: ActionKeyEnum.AcceptApplication,
			icon: (
				<FontAwesomeIcon icon={solid("check")} style={{ fontSize: "15px" }} />
			),
			disabled: record.status === PoliJobsProposalEnrollmentStatus.Accepted,
			title: t("polijobs.acceptApplication"),
		});
		actions.push({
			label: t("polijobs.rejectApplication"),
			key: ActionKeyEnum.RejectApplication,
			icon: (
				<FontAwesomeIcon icon={solid("cancel")} style={{ fontSize: "15px" }} />
			),
			disabled: record.status === PoliJobsProposalEnrollmentStatus.Rejected,
			title: t("polijobs.rejectApplication"),
		});
		actions.push({
			label: t("polijobs.rejectCV"),
			key: ActionKeyEnum.RejectCV,
			icon: (
				<FontAwesomeIcon
					icon={solid("file-circle-xmark")}
					style={{ fontSize: "15px" }}
				/>
			),
			disabled: record.status === PoliJobsProposalEnrollmentStatus.CvRejected,
			title: t("polijobs.rejectCV"),
		});
		actions.push({
			label: t("polijobs.editNotes"),
			key: ActionKeyEnum.AddNotes,
			icon: (
				<FontAwesomeIcon
					icon={solid("pen-to-square")}
					style={{ fontSize: "15px" }}
				/>
			),
			title: t("polijobs.editNotes"),
		});

		return actions;
	};

	const onMenuClick = (e: any, record: PoliJobsCandidateDTO) => {
		if (e.key == ActionKeyEnum.AcceptApplication) {
			setModalTitle(t("polijobs.acceptApplication"));
			setModalText(t("polijobs.acceptApplicationMessage"));
			setIsModalVisible(true);
			setHandleFunction(
				() => () => acceptPoliJobsApplication(record.enrollmentId!)
			);
		} else if (e.key == ActionKeyEnum.RejectApplication) {
			setModalTitle(t("polijobs.rejectApplication"));
			setModalText(t("polijobs.rejectApplicationMessage"));
			setIsModalVisible(true);
			setHandleFunction(
				() => () => rejectPoliJobsApplication(record.enrollmentId!)
			);
		} else if (e.key == ActionKeyEnum.RejectCV) {
			setModalTitle(t("polijobs.rejectCV"));
			setModalText(t("polijobs.rejectCVMessage"));
			setIsCVRejectModalVisible(true);
			setHandleFunction(() => () => rejectStudentCV(record.enrollmentId!));
		} else if (e.key == ActionKeyEnum.AddNotes) {
			setModalTitle(t("polijobs.editNotes"));
			setModalText(t("polijobs.editNotesMessage"));
			setIsEditNotesModalVisible(true);
			notesForm.setFieldsValue({ notes: record.notes! });
			setHandleFunction(() => () => editApplicantNotes(record.enrollmentId!));
		}
	};

	const columns = [
		{
			title: t("polijobs.name"),
			dataIndex: "applicantName",
			key: "applicantName",
			fixed: "left" as "left",
			render: (text: string | undefined, record: PoliJobsCandidateDTO) => (
				<a onClick={() => goToStudentProfile(record.applicantId)}>
					<b>{text}</b>
				</a>
			),
		},
		{
			title: t("polijobs.faculty"),
			dataIndex:
				i18n.language === "ro"
					? "applicantFacultyNameRo"
					: "applicantFacultyNameEn",
			key:
				i18n.language === "ro"
					? "applicantFacultyNameRo"
					: "applicantFacultyNameEn",
		},
		{
			title: t("polijobs.studyProgram"),
			dataIndex: "applicantStudyProgram",
			key: "applicantStudyProgram",
		},
		{
			title: t("polijobs.year"),
			dataIndex: "applicantYear",
			key: "applicantYear",
			render: (_text: string | undefined, record: PoliJobsCandidateDTO) =>
				t("account." + record.applicantYear),
		},
		{
			title: t("polijobs.email"),
			dataIndex: "applicantEmail",
			key: "applicantEmail",
		},
		{
			title: t("polijobs.phone"),
			dataIndex: "applicantPhone",
			key: "applicantPhone",
		},
		{
			title: t("polijobs.cv"),
			dataIndex: "applicantCvFileName",
			key: "applicantCvFileName",
			responsive: ["md"] as unknown as Breakpoint[],
			render: (_text: string | undefined, record: PoliJobsCandidateDTO) => (
				<Button
					title={"CV"}
					style={{
						backgroundColor: "transparent",
					}}
					onClick={() =>
						downloadFile(
							downloadStudentCv(record.applicantId!),
							t("documents.Cv") + "_" + record.applicantName
						).catch(downloadFail)
					}
				>
					<FontAwesomeIcon
						icon={solid("download")}
						style={{ fontSize: "15px" }}
					/>
				</Button>
			),
		},
		{
			title: t("polijobs.actions"),
			dataIndex: "actions",
			fixed: "right" as "right",
			width: 85,
			hidden: props.type == InternType.Trainee,
			render: (_text: string | undefined, record: any) => (
				<div>
					<Dropdown
						placement="bottomLeft"
						overlay={
							<Menu
								onClick={(e) => onMenuClick(e, record)}
								items={getActions(record)}
							/>
						}
					>
						<FontAwesomeIcon
							icon={solid("bars")}
							style={{
								cursor: "pointer",
								height: "1rem",
								padding: "6px 0px",
								marginLeft: "13px",
							}}
						/>
					</Dropdown>
				</div>
			),
		},
	];

	const onPageUpdate = useCallback(
		(page: number, pageSize: number) => {
			query.update({
				page: page.toString(),
				pageSize: pageSize.toString(),
			});
		},
		[query]
	);

	const handleClose = () => {
		rejectForm.setFieldsValue({ rejectMessage: "" });
		notesForm.setFieldsValue({ notes: "" });
		setIsCVRejectModalVisible(false);
		setIsEditNotesModalVisible(false);
	};

	return (
		<div className="flex flex-col gap-4">
			<div className="px-4 pb-10 flex flex-col">
				<div className="flex flex-col gap-10">
					<div className="flex flex-col md:flex-row justify-between items-center">
						<Typography.Title level={3} className="my-0">
							{data?.totalCount ?? 0} {t("filtering.results")}
						</Typography.Title>
						{!loading && (
							<Pagination
								defaultCurrent={data?.page}
								defaultPageSize={data?.pageSize}
								total={data?.totalCount}
								onChange={onPageUpdate}
								locale={{ items_per_page: t("pagination") }}
							/>
						)}
					</div>

					<Table
						locale={locale}
						columns={columns}
						dataSource={data?.data ?? []}
						pagination={false}
						scroll={{ x: "calc(300px + 50%)", y: 420 }}
						rowKey={(record) => record.applicantId!}
					/>
				</div>
			</div>
			<ConfirmationModal
				modalText={modalText}
				handleFunction={handleFunction}
				modalVisibility={isModalVisible}
				title={modalTitle}
				changeModalVisibility={() => setIsModalVisible(false)}
				spinning={spinning}
			/>
			<Modal
				open={isCVRejectModalVisible}
				onOk={handleFunction}
				onCancel={handleClose}
				title={modalTitle}
				width={
					window.innerWidth > 800
						? "35%"
						: window.innerWidth < 450
						? "80%"
						: "70%"
				}
				footer={
					<CustomModalFooter
						handleClose={handleClose}
						spinning={spinning}
						handleSave={handleFunction}
						confirmButtonName={t("account.confirm")}
					/>
				}
			>
				<Spin spinning={spinning}>
					<div>
						<Typography>{modalText}</Typography>
						<CustomForm form={rejectForm} style={{ marginBottom: "0px" }}>
							<Form.Item
								name="rejectMessage"
								rules={[
									{
										required: true,
										message: t("students.requiredField"),
									},
								]}
							>
								<TextArea
									autoSize={{ minRows: 3, maxRows: 8 }}
									style={{ width: "30vw", marginTop: "10px" }}
								/>
							</Form.Item>
						</CustomForm>
					</div>
				</Spin>
			</Modal>
			<Modal
				open={isEditNotesModalVisible}
				onOk={handleFunction}
				onCancel={handleClose}
				title={modalTitle}
				width={
					window.innerWidth > 800
						? "35%"
						: window.innerWidth < 450
						? "80%"
						: "70%"
				}
				footer={
					<CustomModalFooter
						handleClose={handleClose}
						spinning={spinning}
						handleSave={handleFunction}
						confirmButtonName={t("account.confirm")}
					/>
				}
			>
				<Spin spinning={spinning}>
					<div>
						<Typography>{modalText}</Typography>
						<CustomForm form={notesForm} style={{ marginBottom: "0px" }}>
							<Form.Item name="notes">
								<TextArea
									autoSize={{ minRows: 3, maxRows: 8 }}
									style={{ width: "30vw", marginTop: "10px" }}
								/>
							</Form.Item>
						</CustomForm>
					</div>
				</Spin>
			</Modal>
		</div>
	);
};

export default PoliJobsApplicantsTable;
