import {
	Button,
	Col,
	DatePicker,
	Divider,
	Form,
	Input,
	InputNumber,
	Modal,
	Row,
	Select,
	Spin,
} from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, useParams } from "react-router-dom";
import ConfirmationModal from "../../Containers/ConfirmationModal";
import { PoliJobsProposalAddUpdateDTO } from "../../Api";
import { theme } from "../../theme";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../Notifications/NotificationsUtils";
import styles from "./AddPoliJobsProposalForm.module.scss";
import { StatusCodes } from "http-status-codes";
import { useQuery } from "react-query";
import {
	addPoliJobsProposal,
	getPoliJobsProposalById,
	updatePoliJobsProposalInfo,
} from "../../Requests/polijobs-proposals-requests";
import {
	getAllCompanies,
	getSpecificPoliJobsProposal,
} from "../../utils/reactQueriesConstants";
import { useIsPoliJobsAdmin } from "../../utils/utilFunctions";
import { AddPoliJobsProposalFormInit } from "./AddPoliJobsProposalFormInit";
import useDebounce from "../../Hooks/debounce";
import { getCompanies } from "../../Requests/company-requests";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import CustomForm from "../../CustomComponents/CustomForm";
import { useAddUpdateCompanyModalFormController } from "../Forms/AddUpdateCompanyModalForm/AddUpdateCompanyModalForm.controller";
import CustomEditor from "../../CustomComponents/CustomEditor";
import { Routes } from "../../utils/routes";
import CustomAutocomplete from "../CustomAutocomplete/CustomAutocomplete";

const { RangePicker } = DatePicker;
const dateFormatList = "YYYY-MM-DD";

const { Option } = Select;

const AddPoliJobsProposalForm = () => {
	const getPoliJobsProposal = async (poliJobsProposalId?: string) => {
		if (!poliJobsProposalId) {
			return null;
		}

		return await getPoliJobsProposalById(poliJobsProposalId);
	};

	const { t } = useTranslation();
	const [form] = Form.useForm<any>();
	const [redirect, setRedirect] = useState(false);
	const [spinning, setSpinning] = useState(false);
	const [cancelModalVisibility, setCancelModalVisibility] = useState(false);
	const [saveModalVisibility, setSaveModalVisibility] = useState(false);
	const isPoliJobsAdmin = useIsPoliJobsAdmin();
	const [companySearch, setCompanySearch] = useState("");
	const debouncedCompanySearch = useDebounce(companySearch, 1000);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [description, setDescription] = useState("");
	const [location, setLocation] = useState("");
	const [placeId, setPlaceId] = useState("");

	const { poliJobsProposalId } = useParams<{ poliJobsProposalId?: string }>();
	const { defaultValues, yupSync } = AddPoliJobsProposalFormInit();

	const { state, actions, computed } = useAddUpdateCompanyModalFormController({
		visibility: isModalVisible,
		record: null,
		isEdit: false,
		onCloseCallBack: () => setIsModalVisible(false),
	});

	const openGetErrorNotification = (_error: any) => {
		openNotification(
			t("internships.error"),
			t("internships.fetchDataError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	useEffect(() => {
		// only when adding the engagementType can change - prevent this from happening on updates
		if (!poliJobsProposalId) {
			form.setFieldsValue({ ...form.getFieldsValue() });
		}
	}, [poliJobsProposalId, form]);

	useQuery(
		[getSpecificPoliJobsProposal, poliJobsProposalId],
		() => getPoliJobsProposal(poliJobsProposalId),
		{
			onError: (err) => {
				openGetErrorNotification(err);
			},
			onSuccess: (data) => {
				if (data) {
					form.setFieldsValue({
						name: data.poliJobsProposalName,
						company: data.companyId,
						companyDescription: data.companyDescription,
						url: data.url,
						numberOfPositions: data.numberOfPositions,
						startingDate: moment(data.startingDate),
						availabilityAd: [
							moment(data.availableFrom, dateFormatList),
							moment(data.availableTo, dateFormatList),
						],
					});
					setDescription(data.companyDescription!);
					setLocation(data.location!);
					setPlaceId(data.placeId!);
				}
			},
			refetchOnWindowFocus: false,
		}
	);

	const { data: companies, isLoading: isLoadingCompanies } = useQuery(
		[getAllCompanies, isPoliJobsAdmin, debouncedCompanySearch],
		async () => {
			if (!isPoliJobsAdmin) {
				return null;
			}

			return getCompanies(debouncedCompanySearch, 1, 25).then((e) =>
				e.data?.map((e) => {
					return { id: e.id, name: e.commercialName };
				})
			);
		},
		{
			onError: (err) => {
				openGetErrorNotification(err);
			},
			refetchOnWindowFocus: false,
		}
	);

	const openSaveErrorNotification = (error: any) => {
		if (error.status && error.status === StatusCodes.FORBIDDEN) {
			openNotification(
				t("internships.error"),
				t("internships.cannotDecrementPositionsError"),
				NOTIFICATION_TYPES.ERROR
			);
		} else {
			openNotification(
				t("internships.error"),
				t("internships.saveDataError"),
				NOTIFICATION_TYPES.ERROR
			);
		}
	};

	const handleCancel = () => {
		setCancelModalVisibility(false);
		setRedirect(true);
	};

	const openSuccessEditNotificationAndRedirect = () => {
		setRedirect(true);
		openNotification(
			t("internships.editInternship"),
			t("internships.editInternshipSuccess"),
			NOTIFICATION_TYPES.SUCCESS,
			6
		);
	};

	const openSuccessAddNotificationAndRedirect = () => {
		setRedirect(true);
		openNotification(
			t("internships.addInternship"),
			t("internships.addInternshipSuccess"),
			NOTIFICATION_TYPES.SUCCESS,
			6
		);
	};

	const handleChangeLocation = (place: any) => {
		setLocation(place.description);
		setPlaceId(place.place_id);
	};

	const handleSave = () => {
		const state = form.getFieldsValue();

		setSpinning(true);

		/* Create body and send request to add/update PoliJobsProposal */
		let obj: PoliJobsProposalAddUpdateDTO = {
			name: state.name,
			companyId: state.company,
			companyDescription: state.companyDescription,
			url: state.url ? state.url : "",
			location: location,
			placeId: placeId,
			numberOfPositions: state.numberOfPositions,
			startingDate: state.startingDate,
			availableFrom: (state as any).availabilityAd[0],
			availableTo: (state as any).availabilityAd[1],
		};

		(poliJobsProposalId
			? updatePoliJobsProposalInfo(poliJobsProposalId, obj)
			: addPoliJobsProposal(obj)
		)
			.then(
				poliJobsProposalId
					? openSuccessEditNotificationAndRedirect
					: openSuccessAddNotificationAndRedirect
			)
			.catch(openSaveErrorNotification)
			.finally(() => {
				setSpinning(false);
				setSaveModalVisibility(false);
			});
	};

	const handleSelectedCompany = (selected: string) => {
		form.setFieldsValue({
			...form.getFieldsValue(),
			company: selected,
			engagementType: undefined,
		} as any);
	};

	if (redirect) {
		return (
			<Redirect
				to={{
					pathname: Routes.POLIJOBS,
				}}
			/>
		);
	} else {
		return (
			<div className="container mx-auto px-4 py-10 flex flex-col gap-8">
				<Modal
					open={isModalVisible}
					onCancel={() => setIsModalVisible(false)}
					title={t("polijobs.addForm.addContactCompany")}
					width={window.innerWidth > 1215 ? "30%" : "80%"}
					footer={null}
				>
					<Spin spinning={state.spinning}>
						<CustomForm
							layout={theme.layout}
							form={state.form}
							onFinish={actions.handleFinish}
						>
							<Form.Item
								name="cui"
								label={`${t("companies.cui")}:`}
								rules={[computed.yupSync]}
								initialValue={computed.defaultValues.cui}
								required
							>
								<Input placeholder={t("companies.cui")} />
							</Form.Item>
							<Form.Item
								name="name"
								label={`${t("companies.name")}:`}
								rules={[computed.yupSync]}
								initialValue={computed.defaultValues.name}
								required
							>
								<Input placeholder={t("companies.name")} />
							</Form.Item>

							<Form.Item
								name="commercialName"
								label={`${t("companies.commercialName")}:`}
								rules={[computed.yupSync]}
								initialValue={computed.defaultValues.commercialName}
								required
							>
								<Input placeholder={t("companies.commercialName")} />
							</Form.Item>

							<Form.Item
								name="address"
								label={`${t("companies.address")}:`}
								rules={[computed.yupSync]}
								initialValue={computed.defaultValues.address}
								required
							>
								<Input placeholder={t("companies.address")} />
							</Form.Item>

							<Form.Item
								name="phone"
								label={`${t("companies.phone")}:`}
								rules={[computed.yupSync]}
								initialValue={computed.defaultValues.phone}
								required
							>
								<Input placeholder={t("companies.phone")} />
							</Form.Item>

							<Form.Item
								name="emailContact"
								label={`${t("companies.email")}:`}
								rules={[computed.yupSync]}
								initialValue={computed.defaultValues.emailContact}
								required
							>
								<Input placeholder={t("companies.email")} />
							</Form.Item>

							<Form.Item
								name="domainOfActivity"
								label={`${t("companies.domainOfActivity")}:`}
								rules={[computed.yupSync]}
								initialValue={computed.defaultValues.domainOfActivity}
								required
							>
								<Input placeholder={t("companies.domainOfActivity")} />
							</Form.Item>

							<Form.Item
								name="fax"
								label={`${t("companies.fax")}:`}
								rules={[computed.yupSync]}
								initialValue={computed.defaultValues.fax}
							>
								<Input placeholder={t("companies.fax")} />
							</Form.Item>

							<Form.Item>
								<Button type="primary" htmlType="submit">
									{t("companies.addCompany")}
								</Button>
							</Form.Item>
						</CustomForm>
					</Spin>
				</Modal>
				<Form
					form={form}
					onFinish={() => {
						setSaveModalVisibility(true);
					}}
					layout={theme.layout}
					action={Routes.POLIJOBS}
				>
					<div className={styles.firstLine}>
						<Form.Item
							required
							name="name"
							label={t("polijobs.addForm.offerTitle") + ":"}
							rules={[yupSync]}
						>
							<Input
								className={styles.antItem}
								placeholder={t("polijobs.addForm.offerTitle")}
							/>
						</Form.Item>

						<Form.Item
							required
							name="company"
							label={t("polijobs.addForm.company") + ":"}
							style={{ marginTop: "-10px" }}
							rules={[yupSync]}
						>
							<Select
								className={styles.antSelect}
								allowClear
								showSearch
								style={{ width: "100%", textAlign: "left" }}
								filterOption={false}
								disabled={poliJobsProposalId !== undefined}
								onSearch={setCompanySearch}
								placeholder={t("polijobs.addForm.company")}
								onChange={handleSelectedCompany}
								notFoundContent={
									isLoadingCompanies ? (
										<Spin size="small" />
									) : (
										t("polijobs.addForm.notFoundContent")
									)
								}
								dropdownRender={(menu) => (
									<div>
										{menu}
										<Divider style={{ margin: "4px 0" }} />
										<div
											style={{ padding: "4px 8px", cursor: "pointer" }}
											onMouseDown={(e) => e.preventDefault()}
											onClick={() => setIsModalVisible(true)}
										>
											<FontAwesomeIcon icon={solid("plus")} />{" "}
											{t("polijobs.addForm.addContactCompany")}
										</div>
									</div>
								)}
							>
								{companies?.map((company) => (
									<Option key={company.id} value={company.id}>
										{company.name}
									</Option>
								))}
							</Select>
						</Form.Item>

						<Form.Item
							name="companyDescription"
							label={t("polijobs.addForm.companyDescription") + ":"}
							rules={[yupSync]}
						>
							<CustomEditor
								onEditorChange={(companyDescription: string) => {
									form.setFieldsValue({
										...form.getFieldsValue(),
										companyDescription,
									} as any);
								}}
								value={description}
							/>
						</Form.Item>

						<Form.Item
							name="url"
							label={t("polijobs.addForm.url") + ":"}
							rules={[yupSync]}
						>
							<Input placeholder={t("polijobs.addForm.url")}></Input>
						</Form.Item>

						<Row className="w-full flex flex-nowrap gap-10">
							<Col className="w-full">
								<Form.Item
									required
									name="location"
									initialValue={defaultValues?.location}
									label={t("polijobs.addForm.location") + ":"}
								>
									<CustomAutocomplete
										onPlaceChange={handleChangeLocation}
										locationInput={location}
									/>
								</Form.Item>
							</Col>

							<Col className="w-full">
								<Form.Item
									required
									name="numberOfPositions"
									initialValue={defaultValues?.numberOfPositions}
									label={t("polijobs.addForm.numberOfPositions") + ":"}
									rules={[yupSync]}
								>
									<InputNumber
										className="w-full"
										placeholder={t("polijobs.addForm.numberOfPositions")}
									></InputNumber>
								</Form.Item>
							</Col>

							<Col className="w-full">
								<Form.Item
									required
									name="startingDate"
									initialValue={defaultValues?.startingDate}
									label={t("polijobs.addForm.startingDate") + ":"}
									rules={[yupSync]}
								>
									<DatePicker
										format={dateFormatList}
										style={{ width: "100%", height: "35px" }}
										placeholder={t("polijobs.addForm.startingDate")}
									/>
								</Form.Item>
							</Col>
						</Row>

						<Form.Item
							required
							name="availabilityAd"
							initialValue={[
								defaultValues?.availableFrom,
								defaultValues?.availableTo,
							]}
							label={t("polijobs.addForm.availabilityAd") + ":"}
							rules={[yupSync]}
						>
							<RangePicker
								format={dateFormatList}
								style={{ width: "100%", height: "35px" }}
							/>
						</Form.Item>
					</div>

					<div>
						<div className="flex items-center justify-end gap-2">
							<Button
								style={{
									background: theme.green,
									color: theme.white,
									boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
								}}
								onClick={() => setCancelModalVisibility(true)}
							>
								{t("internships.addInternshipForm.cancel")}
							</Button>

							<Button
								htmlType="submit"
								type={"primary"}
								style={{
									background: theme.secondColor,
									color: theme.white,
									boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
								}}
							>
								{poliJobsProposalId
									? t("internships.addInternshipForm.updateButton")
									: t("internships.addInternshipForm.saveButton")}
							</Button>
						</div>

						<ConfirmationModal
							modalText={t("internships.cancelMessage")}
							handleFunction={handleCancel}
							modalVisibility={cancelModalVisibility}
							changeModalVisibility={() => setCancelModalVisibility(false)}
							title=""
							spinning={spinning}
						/>

						<ConfirmationModal
							modalText={t("internships.saveMessage")}
							handleFunction={handleSave}
							modalVisibility={saveModalVisibility}
							title=""
							changeModalVisibility={() => setSaveModalVisibility(false)}
							spinning={spinning}
						/>
					</div>
				</Form>
			</div>
		);
	}
};

export default AddPoliJobsProposalForm;
