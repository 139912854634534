/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
	PoliJobsProposalEnrollmentStatus,
	PoliJobsProposalEnrollmentStatusFromJSON,
	PoliJobsProposalEnrollmentStatusFromJSONTyped,
	PoliJobsProposalEnrollmentStatusToJSON,
} from "./PoliJobsProposalEnrollmentStatus";
import {
	StudentYearEnum,
	StudentYearEnumFromJSON,
	StudentYearEnumFromJSONTyped,
	StudentYearEnumToJSON,
} from "./StudentYearEnum";

/**
 *
 * @export
 * @interface PoliJobsCandidateDTO
 */
export interface PoliJobsCandidateDTO {
	/**
	 *
	 * @type {string}
	 * @memberof PoliJobsCandidateDTO
	 */
	readonly enrollmentId?: string;
	/**
	 *
	 * @type {string}
	 * @memberof PoliJobsCandidateDTO
	 */
	readonly applicantId?: string;
	/**
	 *
	 * @type {string}
	 * @memberof PoliJobsCandidateDTO
	 */
	readonly applicantName?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof PoliJobsCandidateDTO
	 */
	applicantFacultyNameEn?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof PoliJobsCandidateDTO
	 */
	applicantFacultyNameRo?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof PoliJobsCandidateDTO
	 */
	applicantStudyProgram?: string | null;
	/**
	 *
	 * @type {StudentYearEnum}
	 * @memberof PoliJobsCandidateDTO
	 */
	applicantYear?: StudentYearEnum;
	/**
	 *
	 * @type {string}
	 * @memberof PoliJobsCandidateDTO
	 */
	readonly applicantEmail?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof PoliJobsCandidateDTO
	 */
	readonly applicantPhone?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof PoliJobsCandidateDTO
	 */
	applicantCvFileName?: string | null;
	/**
	 *
	 * @type {PoliJobsProposalEnrollmentStatus}
	 * @memberof PoliJobsCandidateDTO
	 */
	status?: PoliJobsProposalEnrollmentStatus;
	/**
	 *
	 * @type {string}
	 * @memberof PoliJobsCandidateDTO
	 */
	readonly notes?: string | null;
}

export function PoliJobsCandidateDTOFromJSON(json: any): PoliJobsCandidateDTO {
	return PoliJobsCandidateDTOFromJSONTyped(json, false);
}

export function PoliJobsCandidateDTOFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): PoliJobsCandidateDTO {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		enrollmentId: !exists(json, "enrollmentId")
			? undefined
			: json["enrollmentId"],
		applicantId: !exists(json, "applicantId") ? undefined : json["applicantId"],
		applicantName: !exists(json, "applicantName")
			? undefined
			: json["applicantName"],
		applicantFacultyNameEn: !exists(json, "applicantFacultyNameEn")
			? undefined
			: json["applicantFacultyNameEn"],
		applicantFacultyNameRo: !exists(json, "applicantFacultyNameRo")
			? undefined
			: json["applicantFacultyNameRo"],
		applicantStudyProgram: !exists(json, "applicantStudyProgram")
			? undefined
			: json["applicantStudyProgram"],
		applicantYear: !exists(json, "applicantYear")
			? undefined
			: StudentYearEnumFromJSON(json["applicantYear"]),
		applicantEmail: !exists(json, "applicantEmail")
			? undefined
			: json["applicantEmail"],
		applicantPhone: !exists(json, "applicantPhone")
			? undefined
			: json["applicantPhone"],
		applicantCvFileName: !exists(json, "applicantCvFileName")
			? undefined
			: json["applicantCvFileName"],
		status: !exists(json, "status")
			? undefined
			: PoliJobsProposalEnrollmentStatusFromJSON(json["status"]),
		notes: !exists(json, "notes") ? undefined : json["notes"],
	};
}

export function PoliJobsCandidateDTOToJSON(
	value?: PoliJobsCandidateDTO | null
): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		applicantFacultyNameEn: value.applicantFacultyNameEn,
		applicantFacultyNameRo: value.applicantFacultyNameRo,
		applicantStudyProgram: value.applicantStudyProgram,
		applicantYear: StudentYearEnumToJSON(value.applicantYear),
		applicantCvFileName: value.applicantCvFileName,
		status: PoliJobsProposalEnrollmentStatusToJSON(value.status),
	};
}
